import Lottie from 'lottie-react';
import tableClosed from '../assets/animations/table_closed.json';
import { Link, createFileRoute } from '@tanstack/react-router';

function SessionNotFound() {
  return (
    <div className="flex flex-col justify-center items-center w-full h-screen p-4">
      <p className = "text-2xl pb-2">Sorry!</p>
      <Lottie className="w-auto h-[20vh]" animationData={tableClosed} loop={true} />
      <p className = "text-xl pt-4">Table has been closed</p>
      <p className = "text-xl pb-4">by the restaurant</p>
      <Link to="/" className="text-primary underline font-medium">
        Go back to home
      </Link>
    </div>
  );
}

export const Route = createFileRoute('/sessionNotFound')({
  component: SessionNotFound,
});

export default SessionNotFound;
