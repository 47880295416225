/* eslint-disable @typescript-eslint/no-explicit-any */
import { CustomisationType, ParsedUrl } from '../Interface';
import { LocalStorageEnum } from '../constants/enum';
import { jwtDecode } from 'jwt-decode';

export function truncateString(input: string, maxLength = 10): string {
  if (input.length > maxLength) {
    return input.substring(0, maxLength) + '...';
  }
  return input;
}

export function capitalizeFirstLetter(str: string) {
  if (!str) return str;
  return str.charAt(0).toUpperCase() + str.slice(1);
}

export function capitalizeFirstLetterOfEachWord(str: string): string {
  return str
    .split(' ')
    .map(word => capitalizeFirstLetter(word))
    .join(' ');
}

export function getFullWidth(element: HTMLElement | null): number {
  if (!element) {
    throw new Error('Element not found');
  }

  const clone = element.cloneNode(true) as HTMLElement;
  clone.style.position = 'absolute';
  clone.style.visibility = 'hidden';
  clone.style.height = 'auto'; // Ensure height doesn't constrain the width
  document.body.appendChild(clone);
  const width = clone.offsetWidth;
  document.body.removeChild(clone);
  return width;
}

export function parseUrl(url: string): ParsedUrl | null {
  try {
    const parsedUrl = new URL(url);
    const host = parsedUrl.host;
    const pathParts = parsedUrl.pathname.split('/').filter((part) => part);
    const route = pathParts.slice(0, -1).join('/');
    const param = pathParts[pathParts.length - 1];
    return { host, route, param };
  } catch (error) {
    return null;
  }
}

interface AuthTokenPayload {
  exp: number;
}
export function isAuthenticated(): boolean {
  const auth = localStorage.getItem(LocalStorageEnum.AUTH_STORAGE);
  if (auth) {
    const { state } = JSON.parse(auth);
    const { authToken, sessionID } = state;
    if (authToken && sessionID) {
      try {
        const decoded: AuthTokenPayload = jwtDecode(authToken);
        const currentTime = Math.floor(Date.now() / 1000);
        if (decoded.exp > currentTime) {
          return true;
        } else {
          console.warn('Token is expired');
          return false;
        }
      } catch (error) {
        console.error('Invalid token:', error);
        return false;
      }
    }
  }
  return false;
}

export function isEmpty<T>(input: T): boolean {
  if (input == null) {
    // Checks for null or undefined
    return true;
  }

  if (typeof input === 'string') {
    return input.length === 0;
  }

  if (Array.isArray(input)) {
    return input.length === 0;
  }

  if (typeof input === 'object') {
    return Object.keys(input as object).length === 0;
  }

  return false;
}

import deepEqual from 'fast-deep-equal';

// Utility function to pick specified fields from an object
function pickFields<T extends object>(obj: T, fields: (keyof T)[]): Partial<T> {
  const result: Partial<T> = {};
  for (const field of fields) {
    if (field in obj) {
      result[field] = obj[field];
    }
  }
  return result;
}

// Function to compare specified fields of two objects
export function compareSelectedFields<T extends object>(obj1: T, obj2: T, fields: (keyof T)[]): boolean {
  const obj1SelectedFields = pickFields(obj1, fields);
  const obj2SelectedFields = pickFields(obj2, fields);
  return deepEqual(obj1SelectedFields, obj2SelectedFields);
}

export function convertToAMPMFormat(input: string | Date): string {
  const date = typeof input === 'string' ? new Date(input) : input;

  // Format the date to local time in AM/PM format without seconds
  const localTime = date.toLocaleString(undefined, {
    hour: 'numeric',
    minute: 'numeric',
    hour12: true,
  });

  return localTime;
}

export function getCurrentDateTime(input: string | Date): string {
  const date = typeof input === 'string' ? new Date(input) : input;

  const dateString = new Intl.DateTimeFormat('en-GB', {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
  }).format(date);

  const timeString = convertToAMPMFormat(date);

  return `${dateString} ${timeString}`;
}

export function getAuthToken() {
  try {
    const auth = localStorage.getItem(LocalStorageEnum.AUTH_STORAGE);
    if (auth) {
      const { state } = JSON.parse(auth);
      const { authToken } = state;
      return authToken;
    }
    return '';
  } catch (e) {
    return '';
  }
}
import { AxiosError } from 'axios';
import ShowToast from '../components/Toast';

interface ParsedError {
  errorMessage: string;
  statusCode: number | null;
}

export const parseAxiosError = (error: AxiosError): ParsedError => {
  let errorMessage = 'An unexpected error occurred.';
  let statusCode: number | null = null;

  if (error.response) {
    const { data, status } = error.response;
    errorMessage = (data as any).message || errorMessage; // assuming the server sends back an error message
    statusCode = status;
  } else if (error.request) {
    errorMessage = 'No response received from the server. Please check your network connection.';
  } else {
    errorMessage = error.message;
  }

  return { errorMessage, statusCode };
};

export function getCustomisation(customisations: CustomisationType) {
  const size = customisations.size ? customisations.size.size : '';
  const ingredients = customisations.ingredients
    ? customisations.ingredients.map((item) => item.ingredient).join(' . ')
    : '';

  return [size, ingredients].filter((item) => item.length > 0).join(' . ');
}

export const copyToClipboard = (content: string): void => {
  navigator.clipboard.writeText(content).then(
    () => {
      ShowToast({ message: 'Link copied to clipboard', type: 'success' });
    },
    (err) => {
      ShowToast({ message: 'Failed to copy link to clipboard', type: 'error' });
    },
  );
};
