import { motion, useAnimation, useMotionValue } from 'framer-motion';
import React, { useEffect, useRef, useState } from 'react';
import { GrNext, GrCheckmark } from 'react-icons/gr';
import CustomModal from './Modal';
import { useNavigate } from '@tanstack/react-router';
import { useAuthStore } from '../store/authStore';

function SlideToPay({ amount }: { amount: number }) {
  const { userName, tablePIN, sessionID, setCheckout } = useAuthStore((state) => ({
    userName: state.userName,
    tablePIN: state.tablePIN,
    sessionID: state.sessionID,
    setCheckout: state.setCheckout,
  }));

  const divRef = useRef<HTMLDivElement>(null);
  const [divWidth, setDivWidth] = useState<number>(0);
  const [crossedBreakpoint, setCrossedBreakpoint] = useState<boolean>(false);
  const controls = useAnimation();
  const paymentCalled = useRef<boolean>(false);
  const x = useMotionValue(0);
  const [dragEnabled, setDragEnabled] = useState(true);
  const [paymentModalOpen, setPaymentModalOpen] = useState(false);
  const [paymentStatus, setPaymentStatus] = useState('Thank you for dining with us! Hope you had a great time!');
  const navigate = useNavigate();

  useEffect(() => {
    if (divRef.current) {
      const { width } = divRef.current.getBoundingClientRect();
      const computedStyle = window.getComputedStyle(divRef.current);
      const totalPadding = parseFloat(computedStyle.paddingLeft || '0') + parseFloat(computedStyle.paddingRight || '0');
      const totalMargin = parseFloat(computedStyle.marginLeft || '0') + parseFloat(computedStyle.marginRight || '0');
      setDivWidth(width - totalPadding - totalMargin - 47);
    }
  }, [divRef]);

  const handlePayment = () => {
    setDragEnabled(false);
    setTimeout(() => {
      setCheckout(true);
    }, 600);
  };
  const handleDragEnd = (_: MouseEvent | TouchEvent | PointerEvent, info: { point: { x: number } }) => {
    if (info.point.x < (divWidth + 47) / 1.5) {
      controls.start({ x: 0, transition: { type: 'tween', ease: 'linear' } });
    } else {
      controls.start({ x: divWidth, transition: { type: 'tween', ease: 'linear' } });
    }
  };

  const handleDrag = (_: MouseEvent | TouchEvent | PointerEvent, info: { point: { x: number } }) => {
    if (info.point.x < (divWidth + 47) / 1.5) {
      setCrossedBreakpoint(false);
    } else {
      setCrossedBreakpoint(true);
    }
    if (info.point.x >= divWidth + 47 && !paymentCalled.current) {
      paymentCalled.current = true;
      handlePayment();
    }
  };

  const onAnimationComplete = () => {
    if (x.get() === divWidth && !paymentCalled.current) {
      paymentCalled.current = true;
      handlePayment();
    }
  };
  return (
    <div ref={divRef} className="relative w-full rounded-full h-12 bg-secondary-300 flex justify-start items-center">
      <p className="text-white absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
        Slide to Pay | &#8377; {amount}
      </p>
      <motion.div
        drag={dragEnabled ? 'x' : false}
        onDragEnd={handleDragEnd}
        onDrag={handleDrag}
        animate={controls}
        style={{ x }}
        onAnimationComplete={onAnimationComplete}
        whileTap={{ boxShadow: '0px 0px 15px rgba(0,0,0,0.2)' }}
        dragConstraints={{ left: 0, right: divWidth }}
        dragElastic={0}
        className="h-10 w-10 ml-[3px] rounded-full bg-white flex justify-center items-center"
      >
        {crossedBreakpoint ? (
          <GrCheckmark className="relative text-2xl text-grey_text transition-all duration-500" />
        ) : (
          <>
            <GrNext className="relative text-2xl text-grey_text left-3.5 transition-all duration-500" />
            <GrNext className="relative text-2xl text-grey_text -left-3 transition-all duration-500" />
          </>
        )}
      </motion.div>
      <CustomModal
        isOpen={paymentModalOpen}
        onClose={() => {
          setPaymentModalOpen(false);
          navigate({ to: '/' });
        }}
      >
        <div className="">
          <p>{paymentStatus}</p>
        </div>
      </CustomModal>
    </div>
  );
}

export default SlideToPay;
