import { createRootRoute, Outlet, useMatch } from '@tanstack/react-router';
// import { TanStackRouterDevtools } from '@tanstack/router-devtools';
import Navbar from '../components/Navbar';
import { useEffect, useState } from 'react';
import { useCartStore } from '../store/cartStore';
import NotFound from '../components/NotFound';

export const Route = createRootRoute({
  component: Root,
  notFoundComponent: () => <NotFound />,
});

function Root() {
  const currentPath = window.location.pathname;
  const withouNavbarRoutes = ['/orderReview', '/order', '/bill'];
  const { shouldDisplayNavbar, changeNavbarVisibility } = useCartStore((state) => ({
    shouldDisplayNavbar: state.shouldDisplayNavbar,
    changeNavbarVisibility: state.changeNavbarVisibility,
  }));
  useEffect(() => {
    changeNavbarVisibility(!withouNavbarRoutes.some((item) => currentPath.includes(item)));
  }, []);
  return (
    <>
      <Outlet />
      {(!withouNavbarRoutes.some((item) => currentPath.includes(item)) || shouldDisplayNavbar) && <Navbar />}
      {/* <TanStackRouterDevtools /> */}
    </>
  );
}
