export enum LocalStorageEnum {
  AUTH_TOKEN = 'authToken',
  REFRESH_TOKEN = 'refreshToken',
  ID_TOKEN = 'idToken',
  AUTH_STORAGE = 'auth-storage',
  CART = 'cart',
}

export enum ReactQueryEnum {
  RESTAURANTS = 'restaurants',
  RESTAURANT = 'restaurant',
  CATEGORY = 'category',
  MENU = 'menu',
  TABLE_STATUS = 'tableStatus',
  SESSION = 'session',
  BILL = 'bill',
}

export enum TableStatusEnum {
  OPEN = 'open',
  ON_GOING = 'ongoing',
  CHECKOUT = 'checkout',
}

export enum PersistentStateEnum {
  AUTH_TOKEN = 'authToken',
  REFRESH_TOKEN = 'refreshToken',
  ID_TOKEN = 'idToken',
  SELECTED_INDEX = 'selectedIndex',
  SELECTED_FILTERS = 'selectedFilters',
}

export enum socketCallEnum {
  JOIN_SESSION = 'joinSession',
  CONNECT = 'connect',
  ADD_DISH = 'addDish',
  UPDATE_CART = 'updateCart',
  CART_DISH = 'cartDish',
  DISHES_ON_TABLE = 'dishesOnTable',
  UPDATE_DISH = 'updateDish',
  UPDATE_DISH_V2 = 'updateDishV2',
  USERS_ON_TABLE = 'usersOnTable',
  ACTIVITY = 'activity',
  GET_USERS = 'getUsers',
  PLACE_ORDER = 'placeOrder',
  ORDER_STATUS = 'orderStatus',
  EXCEPTION = 'exception',
  REFFRESH_CART = 'refreshCart',
}

export enum SessionStatus {
  OPEN = 'open',
  ONGOING = 'ongoing',
  CHECKOUT = 'checkout',
}

export enum OrderStatus {
  PENDING = 'pending',
  COMPLETED = 'completed',
  REJECTED = 'rejected',
  CANCELLED = 'cancelled',
  CONFIRMED = 'confirmed',
  SERVED = 'served',
}

export enum OrderedDishStatus {
  REJECTED = 'rejected',
  PENDING = 'pending',
  PREPARING = 'preparing',
  SERVED = 'served',
  CONFIRMED = 'confirmed',
}
