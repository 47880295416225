import { toast, ToastOptions, ToastContainer, Bounce } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const defaultOptions: ToastOptions = {
  position: 'bottom-center',
  autoClose: 5000,
  hideProgressBar: true,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
  theme: 'light',
  transition: Bounce,
};

export default function ShowToast({
  message,
  type = 'info',
  options = defaultOptions,
}: {
  message: string;
  type: ToastOptions['type'];
  options?: ToastOptions;
}) {
  const toastOptions = { ...defaultOptions, ...options };
  switch (type) {
    case 'success':
      toast.success(message, toastOptions);
      break;
    case 'warning':
      toast.warning(message, toastOptions);
      break;
    case 'error':
      toast.error(message, toastOptions);
      break;
    case 'info':
    default:
      toast.info(message, toastOptions);
      break;
  }
}

export const ToastContainerWrapper = () => {
  return (
    <ToastContainer
      position={defaultOptions.position}
      autoClose={defaultOptions.autoClose}
      hideProgressBar={defaultOptions.hideProgressBar}
      newestOnTop={false}
      closeOnClick={defaultOptions.closeOnClick}
      rtl={false}
      pauseOnFocusLoss
      draggable
      pauseOnHover
      theme={defaultOptions.theme}
      transition={defaultOptions.transition}
      stacked={true}
    />
  );
};
