import { Link, createFileRoute, redirect, useNavigate } from '@tanstack/react-router';
import { motion, useAnimation } from 'framer-motion';
import React, { useEffect, useState } from 'react';
import FoodTypeIndicator from '../../components/FoodTypeIndicator';
import { DiningSession, FoodType } from '../../Interface';
import SlideToPay from '../../components/SlideToPay';
import SessionExpiredError from '../../errors/SessionExpiredError';
import { useQuery } from '@tanstack/react-query';
import { ReactQueryEnum } from '../../constants/enum';
import { useAuthStore } from '../../store/authStore';
import useAxios from '../../hooks/useAxios';
import { AxiosError } from 'axios';
import { IBill } from '../../Interface/Bill';
import { copyToClipboard, getCurrentDateTime, getCustomisation, isAuthenticated } from '../../utils/common';
import { BsCash } from 'react-icons/bs';
import clsx from 'clsx';
import { GoShareAndroid } from 'react-icons/go';
import { Rating } from 'react-simple-star-rating';
import { ToastContainerWrapper } from '../../components/Toast';
import Share from '../../components/Share';
import payment_successful from '../../assets/animations/payment_successful.json'; // Adjust the path as needed
import Lottie from 'lottie-react';
import Button from '../../components/Button';
import Loading from '../../components/Loading';
import CustomError from '../../components/Error';

export const Route = createFileRoute('/bill/')({
  beforeLoad: async () => {
    if (!isAuthenticated()) {
      throw redirect({
        to: '/',
        replace: true,
      });
    }
  },
  component: Index,
});

function Review({ restaurantName, id }: { restaurantName: string; id: string }) {
  const navigate = useNavigate({
    from: '/bill/',
  });
  const [rating, setRating] = useState<number>(0);
  const [shareDrawerOpen, setShareDrawerOpen] = useState(false);
  const [isAnimationComplete, setIsAnimationComplete] = useState(false);
  const controls = useAnimation(); // Framer Motion controls for animation
  const lottieControls = useAnimation(); // Lottie controls for animation
  const billUrl = `${import.meta.env.VITE_EMPRESS_HOST}/bill/${id}`;

  const handleRating = (rate: number) => {
    setRating(rate);

    // other logic
  };

  useEffect(() => {
    const timer = setTimeout(
      () => {
        setIsAnimationComplete(true);
      },
      payment_successful.op * (1000 / 30),
    );

    return () => clearTimeout(timer);
  }, []);
  useEffect(() => {
    if (isAnimationComplete) {
      controls.start({ opacity: 1, y: 0 }); // Start animation when isAnimationComplete becomes true
      lottieControls.start({ opacity: 1 }); // Stop Lottie animation loop
    }
  }, [isAnimationComplete, controls]);

  const ratingTexts: Record<number, string> = {
    0: '😞 Oh no, quite bad.',
    0.5: '😞 Oh no, quite bad.',
    1: '😠 Yikes! This was a disaster.',
    1.5: '😕 Could be better.',
    2: '😟 Not great, needs improvement.',
    2.5: '🙁 Below average.',
    3: '😐 Meh, it was okay.',
    3.5: '🙂 Not bad, almost good.',
    4: '😊 Pretty good, enjoyed it!',
    4.5: '😃 Really good, almost perfect!',
    5: '😍 Amazing! Absolutely loved it!',
  };
  const { userName, tablePIN, sessionID, clearTokens } = useAuthStore((state) => ({
    userName: state.userName,
    tablePIN: state.tablePIN,
    sessionID: state.sessionID,
    clearTokens: state.clearTokens,
  }));

  return (
    <div className="h-screen w-full flex flex-col">
      <Share
        url={billUrl}
        title={`Hi there, You can view the bill for ${restaurantName} by clicking the link below.`}
        isOpen={shareDrawerOpen}
        setIsOpen={setShareDrawerOpen}
      />
      <div className="bg-gradient-to-b to-primary from-gradient_black z-[100] pt-4 pb-4 rounded-b-xl">
        <img src="/images/cibo_logo_primary_gold.svg" className="w-[15%] max-w-[80px] mx-auto " alt="Cibo Logo" />
      </div>
      <div className="flex w-full flex-grow  flex-col justify-start items-center">
        <Lottie animationData={payment_successful} loop={false} style={{ width: '100vw', height: 'auto' }} />
        {isAnimationComplete && (
          <motion.div
            initial={{ opacity: 0, y: -50 }} // Initial animation state
            animate={controls} // Animate based on controls
            transition={{ duration: 0.5 }} // Animation duration
            className="w-[85%] mx-auto"
          >
            <p className="text-lg text-justify font-medium">
              Please proceed to the counter to pay using your preferred payment method!
            </p>
            <div
              onClick={() => setShareDrawerOpen(true)}
              className={clsx('w-[85%] mx-auto mt-3 flex justify-center items-center gap-1 cursor-pointer')}
            >
              <p className="underline  text-center">Share bill</p>
              <GoShareAndroid />
            </div>
            <hr className="my-6" />
            <p className="text-justify">
              Thanks for using Cibo! We'd love to hear about your dining experience. Please take a moment to rate it.
            </p>

            <div className="">
              <Rating
                className=" mt-3 mb-2 mx-auto"
                onClick={handleRating}
                allowFraction={true}
                transition={true}
                showTooltip={true}
                tooltipClassName="!bg-transparent !text-black !ml-0"
                tooltipArray={[
                  '😞 Oh no, quite bad.',
                  '😠 Yikes! This was a disaster.',
                  '😕 Could be better.',
                  '😟 Not great, needs improvement.',
                  '🙁 Below average.',
                  '😐 Meh, it was okay.',
                  '🙂 Not bad, almost good.',
                  '😊 Pretty good, enjoyed it!',
                  '😃 Really good, almost perfect!',
                  '😍 Amazing! Absolutely loved it!',
                ]}
                tooltipDefaultText=""
              />
            </div>
          </motion.div>
        )}
        <motion.div
          initial={{ opacity: 0 }} // Initial animation state
          animate={lottieControls} // Animate based on controls
          transition={{ duration: 0.5 }} // Animation duration
          className="w-full bg-white  fixed bottom-0 flex justify-center items-center py-2 border-0 px-5"
        >
          <Button
            className="text-xl"
            title="Go Home"
            type="primary"
            onClick={() => {
              clearTokens();
              navigate({ to: '/', replace: true });
            }}
          />
        </motion.div>
      </div>
    </div>
  );
}

function Index() {
  const [shareDrawerOpen, setShareDrawerOpen] = useState(false);
  const { getWithAuth } = useAxios();
  const { isCaptain } = useAuthStore((state) => ({
    isCaptain: state.isCaptain,
  }));

  const { userName, tablePIN, sessionID, setCheckout, checkout } = useAuthStore((state) => ({
    userName: state.userName,
    tablePIN: state.tablePIN,
    sessionID: state.sessionID,
    setCheckout: state.setCheckout,
    checkout: state.checkout,
  }));

  const {
    data: sessionData,
    isError: isSessionError,
    isLoading: isSessionLoading,
    failureReason,
    error,
  } = useQuery<DiningSession>({
    queryKey: [ReactQueryEnum.SESSION, sessionID],
    queryFn: async () => {
      const response = await getWithAuth(`/app/dining-session/session`);
      return response;
    },
    retry(failureCount, error) {
      if ((error as AxiosError).response?.status === 404) {
        return false;
      }
      if (error instanceof SessionExpiredError) {
        return false;
      }
      return failureCount < 3;
    },
    refetchInterval: 10000,
  });

  const {
    data: BillData,
    isLoading: isBillLoading,
    isError: isBillError,
  } = useQuery<IBill>({
    queryKey: [ReactQueryEnum.BILL, sessionID],
    queryFn: async () => {
      const response = await getWithAuth(`/app/bill/view-bill`);
      return response;
    },
    retry(failureCount, error) {
      if ((error as AxiosError).response?.status === 404) {
        return false;
      }
      if (error instanceof SessionExpiredError) {
        return false;
      }
      return failureCount < 3;
    },
    refetchInterval: 10000,
  });

  if (isBillLoading || isSessionLoading) {
    return <Loading />;
  }

  if (isSessionError || isBillError || !BillData || !sessionData) {
    return (
      <CustomError>
        <p className="font-medium">{error?.message}</p>
        <Link to="/" className="text-primary underline">
          Go to Home
        </Link>
      </CustomError>
    );
  }

  const billUrl = `${import.meta.env.VITE_EMPRESS_HOST}/bill/${BillData._id}`;

  if (checkout) return <Review id={BillData._id} restaurantName={BillData.restaurantName} />;

  return (
    <div className="relative">
      <ToastContainerWrapper />
      <Share
        url={billUrl}
        title={`${BillData.restaurantName} Bill`}
        isOpen={shareDrawerOpen}
        setIsOpen={setShareDrawerOpen}
      />

      <div className=" bg-gradient-to-b to-primary from-gradient_black z-[100] pt-4 pb-4 rounded-b-xl">
        <img src="/images/cibo_logo_primary_gold.svg" className="w-[15%] max-w-[80px] mx-auto " alt="" />
      </div>
      <div className="w-[85%] mx-auto  flex flex-col justify-center items-center shadow-orderHistory rounded-2xl my-3 py-4 px-4">
        <div className="flex justify-center items-center w-full gap-2 mb-3">
          {BillData.restaurantLogoUrl && (
            <img
              src={BillData.restaurantLogoUrl}
              className="h-10  aspect-square shadow-orderHistory rounded-lg"
              alt=""
            />
          )}
          <p className="text-lg font-medium">{BillData.restaurantName}</p>
        </div>
        <div className="flex justify-center items-center gap-1"></div>
        <div className="flex justify-between w-full">
          {/* <p className="text-sm">Order No. 22</p> */}
          <p className="text-sm">Table {BillData.tableName}</p>
          <p className=" text-sm ">{getCurrentDateTime(BillData.billCreationTime)}</p>
        </div>
        <div className="flex justify-between w-full">
          <p className=" text-sm ">{BillData.captainName}</p>
        </div>
      </div>
      <div className="w-[85%] h-fit mx-auto rounded-2xl bg-white relative  shadow-orderHistory px-1 py-3 my-3 ">
        <div className="grid grid-cols-[3fr_1fr_1fr_1fr] gap-2 place-items-strech text-grey_text mb-2 px-2">
          <p>Item</p>
          <p className="text-center">Qty</p>
          <p className="text-center">Price</p>
          <p className="text-right">Amt</p>
        </div>
        <div className="w-full h-fit max-h-[50vh] overflow-y-auto  mt-2 custom-scrollbar px-2">
          {BillData.orders.map((order, index) => (
            <div
              key={index}
              className={clsx('grid grid-cols-[3fr_1fr_1fr_1fr] gap-2 place-items-strech  pb-3    ', {
                'border-b-0 ': index === BillData.orders.length - 1,
                'mb-3 border-b': index !== BillData.orders.length - 1,
              })}
            >
              <div className="flex flex-col justify-start items-start gap-0.5">
                {/* <div className="mt-2">
                  <FoodTypeIndicator size={1} type={order.type} />
                </div> */}
                <p className=" leading-[18px]">{order.name} </p>
                {order.customisations && (
                  <p className="text-xs text-grey_text leading-none">{getCustomisation(order.customisations)}</p>
                )}
              </div>
              <div className="flex justify-center">
                <p className="">{order.quantity}</p>
                {/* <div className="flex border rounded-md px-3 py-1 justify-center items-center gap-2 h-fit w-fit "></div> */}
              </div>
              <div className="text-black_1  flex justify-center items-start">
                <p className="w-fit">&#8377;{order.totalPrice}</p>
              </div>
              <div className="text-black_1  flex justify-end items-start">
                <p className="w-fit">&#8377;{order.amount}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className={clsx('w-[85%] mx-auto   relative  rounded-2xl bg-white   shadow-orderHistory px-3 py-3', {})}>
        {/* <p className=" font-medium">Bill Summary</p> */}
        <div className="grid grid-cols-[3fr_1fr]  gap-1">
          <p>Sub-total</p>
          <p className="text-black_1 justify-self-end">&#8377;{BillData.subTotal}</p>
          {/* <p>Packing Charges</p>
          <p className="text-black_1 justify-self-end">&#8377;20</p> */}
          <p>Taxes</p>
          <p className="text-black_1 justify-self-end">&#8377;{BillData.tax.totalTaxAmount}</p>
        </div>
        <hr className="my-2" />
        <div className="flex  justify-between font-medium">
          <p>Grand Total</p>
          <p className="text-black_1 justify-self-end">&#8377;{BillData.total}</p>
        </div>
      </div>
      <div
        onClick={() => setShareDrawerOpen(true)}
        className={clsx('w-[85%] mx-auto mt-3 flex justify-center items-center gap-1 cursor-pointer', {
          'mb-[130px]': isCaptain,
          'mb-5': !isCaptain,
        })}
      >
        <p className="underline  text-center">Share bill</p>
        <GoShareAndroid />
      </div>
      {isCaptain && (
        <div className="h-fit w-full bg-white  fixed bottom-0 flex flex-col justify-center items-center  border-0 rounded-t-3xl shadow-pay">
          <div className="w-full px-4 my-3">
            <div className="flex gap-2 justify-start items-center">
              <div className="h-8 w-8 border rounded-md border-border_grey flex justify-center items-center">
                <BsCash className="h-5 w-5" />
              </div>
              <div className="space-y-0">
                <p className="text-grey_text text-sm leading-none">Payment Method</p>
                <p className=" leading-none">Cash</p>
              </div>
            </div>
          </div>
          <div className="w-full px-4 mb-2">
            <SlideToPay amount={BillData.total} />
          </div>
        </div>
      )}
    </div>
  );
}

export default Index;
