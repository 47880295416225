import { Link, createFileRoute } from '@tanstack/react-router';
import { motion } from 'framer-motion';
import React, { useState } from 'react';
import FoodTypeIndicator from '../../components/FoodTypeIndicator';
import { DiningSession, FoodType } from '../../Interface';
import SlideToPay from '../../components/SlideToPay';
import SessionExpiredError from '../../errors/SessionExpiredError';
import { useQuery } from '@tanstack/react-query';
import { ReactQueryEnum } from '../../constants/enum';
import { useAuthStore } from '../../store/authStore';
import useAxios from '../../hooks/useAxios';
import { AxiosError } from 'axios';
import { IBill } from '../../Interface/Bill';
import { copyToClipboard, getCurrentDateTime, getCustomisation } from '../../utils/common';
import { BsCash } from 'react-icons/bs';
import clsx from 'clsx';
import { GoShareAndroid } from 'react-icons/go';

import { ToastContainerWrapper } from '../../components/Toast';
import Share from '../../components/Share';
import Loading from '../../components/Loading';
import CustomError from '../../components/Error';

export const Route = createFileRoute('/bill/$billId')({
  component: Bill,
});

function Bill() {
  const { billId } = Route.useParams();
  const [shareDrawerOpen, setShareDrawerOpen] = useState(false);
  const { getWithoutAuth } = useAxios();

  const {
    data: BillData,
    isLoading: isBillLoading,
    isError: isBillError,
  } = useQuery<IBill>({
    queryKey: [ReactQueryEnum.BILL],
    queryFn: async () => {
      const response = await getWithoutAuth(`/app/bill/${billId}/bill-by-id`);
      return response;
    },
    retry(failureCount, error) {
      if ((error as AxiosError).response?.status === 404) {
        return false;
      }
      if (error instanceof SessionExpiredError) {
        return false;
      }
      return failureCount < 3;
    },
    refetchInterval: 10000,
  });

  if (isBillLoading) {
    return <Loading />;
  }

  if (!BillData) {
    return (
      <CustomError>
        <p className="font-medium">Bill not found</p>
        <Link to="/" className="text-primary underline">
          Go to Home
        </Link>
      </CustomError>
    );
  }

  const billUrl = `${import.meta.env.VITE_EMPRESS_HOST}/bill/${billId}`;

  return (
    <div className="relative">
      <ToastContainerWrapper />
      <Share
        url={billUrl}
        title={`Hi there, You can view the bill for ${BillData.restaurantName} by clicking the link below.`}
        isOpen={shareDrawerOpen}
        setIsOpen={setShareDrawerOpen}
      />

      <div className=" bg-gradient-to-b to-primary from-gradient_black z-[100] pt-4 pb-4 rounded-b-xl">
        <img src="/images/cibo_logo_primary_gold.svg" className="w-[15%] max-w-[80px] mx-auto " alt="" />
      </div>
      <div className="w-[85%] mx-auto  flex flex-col justify-center items-center shadow-orderHistory rounded-2xl my-3 py-4 px-4">
        <div className="flex justify-center items-center w-full gap-2 mb-3">
          {BillData.restaurantLogoUrl && (
            <img
              src={BillData.restaurantLogoUrl}
              className="h-10  aspect-square shadow-orderHistory rounded-lg"
              alt=""
            />
          )}
          <p className="text-lg font-medium">{BillData.restaurantName}</p>
        </div>
        <div className="flex justify-center items-center gap-1"></div>
        <div className="flex justify-between w-full">
          {/* <p className="text-sm">Order No. 22</p> */}
          <p className="text-sm">Table {BillData.tableName}</p>
          <p className=" text-sm ">{getCurrentDateTime(BillData.billCreationTime)}</p>
        </div>
        <div className="flex justify-between w-full">
          <p className=" text-sm ">{BillData.captainId}</p>
        </div>
      </div>
      <div className="w-[85%] h-fit mx-auto rounded-2xl bg-white relative  shadow-orderHistory px-1 py-3 my-3 ">
        <div className="grid grid-cols-[3fr_1fr_1fr_1fr] gap-2 place-items-strech text-grey_text mb-2 px-2">
          <p>Item</p>
          <p className="text-center">Qty</p>
          <p className="text-center">Price</p>
          <p className="text-right">Amt</p>
        </div>
        <div className="w-full h-fit max-h-[50vh] overflow-y-auto  mt-2 custom-scrollbar px-2">
          {BillData.orders.map((order, index) => (
            <div
              key={index}
              className={clsx('grid grid-cols-[3fr_1fr_1fr_1fr] gap-2 place-items-strech  pb-3    ', {
                'border-b-0 ': index === BillData.orders.length - 1,
                'mb-3 border-b': index !== BillData.orders.length - 1,
              })}
            >
              <div className="flex flex-col justify-start items-start gap-0.5">
                {/* <div className="mt-2">
                  <FoodTypeIndicator size={1} type={order.type} />
                </div> */}
                <p className=" leading-[18px]">{order.name} </p>
                {order.customisations && (
                  <p className="text-xs text-grey_text leading-none">{getCustomisation(order.customisations)}</p>
                )}
              </div>
              <div className="flex justify-center">
                <p className="">{order.quantity}</p>
                {/* <div className="flex border rounded-md px-3 py-1 justify-center items-center gap-2 h-fit w-fit "></div> */}
              </div>
              <div className="text-black_1  flex justify-center items-start">
                <p className="w-fit">&#8377;{order.totalPrice}</p>
              </div>
              <div className="text-black_1  flex justify-end items-start">
                <p className="w-fit">&#8377;{order.amount}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className={clsx('w-[85%] mx-auto   relative  rounded-2xl bg-white   shadow-orderHistory px-3 py-3', {})}>
        {/* <p className=" font-medium">Bill Summary</p> */}
        <div className="grid grid-cols-[3fr_1fr]  gap-1">
          <p>Sub-total</p>
          <p className="text-black_1 justify-self-end">&#8377;{BillData.subTotal}</p>
          {/* <p>Packing Charges</p>
          <p className="text-black_1 justify-self-end">&#8377;20</p> */}
          <p>Taxes</p>
          <p className="text-black_1 justify-self-end">&#8377;{BillData.tax.totalTaxAmount}</p>
        </div>
        <hr className="my-2" />
        <div className="flex  justify-between font-medium">
          <p>Grand Total</p>
          <p className="text-black_1 justify-self-end">&#8377;{BillData.total}</p>
        </div>
      </div>
      <div
        onClick={() => setShareDrawerOpen(true)}
        className={clsx('w-[85%] mx-auto mt-3 flex justify-center items-center gap-1 cursor-pointer', {})}
      >
        <p className="underline  text-center">Share bill</p>
        <GoShareAndroid />
      </div>
    </div>
  );
}
