import { Link, createFileRoute, redirect, useNavigate, useParams, useSearch } from '@tanstack/react-router';
import React, { useEffect } from 'react';
import CaptainRegister from '../components/CaptainRegister';
import { useQuery } from '@tanstack/react-query';
import { ReactQueryEnum, TableStatusEnum } from '../constants/enum';
import useAxios from '../hooks/useAxios';
import UserRegister from '../components/UserRegister';
import { AxiosError } from 'axios';
import { isAuthenticated } from '../utils/common';
import Loading from '../components/Loading';
import CustomError from '../components/Error';
import { useAuthStore } from '../store/authStore';

type QueryParams = {
  pin?: string;
};
const Register = () => {
  const { pin } = useSearch({
    from: '/register/$tableId',
  }) as QueryParams;
  const { tableId } = Route.useParams();
  const { getWithoutAuth } = useAxios();
  const { setTableId, setRestaurantId } = useAuthStore((state) => ({
    setTableId: state.setTableID,
    setRestaurantId: state.setRestaurantID,
  }));
  const tableStatusResult = useQuery({
    queryKey: [ReactQueryEnum.TABLE_STATUS, tableId],
    queryFn: async () => {
      const response = await getWithoutAuth(`/app/dining-session/table/${tableId}/status`);
      setTableId(tableId);
      setRestaurantId(response.restaurantId);
      return response;
    },
  });

  if (tableStatusResult.isError) {
    return (
      <CustomError>
        <p className="font-medium">{tableStatusResult.error.message}</p>
        <Link to="/" className="text-primary underline">
          Go to Home
        </Link>
      </CustomError>
    );
  }
  if (tableStatusResult.isLoading) {
    return <Loading />;
  }
  return (
    <div className="bg-gradient-to-b from-primary to-gradient_black h-[60vh] w-full relative rounded-bl-[15vh] pt-[10vh]">
      <div className="mb-16 w-[80%] mx-auto">
        <p className="text-white text-xl font-semibold">Welcome To</p>
        <img src="/images/cibo_logo_primary_gold.svg" alt="" />
      </div>
      <div className="w-[85%] h-[50vh] bg-white rounded-2xl shadow-2xl mx-auto flex justify-center items-center">
        {tableStatusResult.data.status === TableStatusEnum.OPEN ? (
          <CaptainRegister
            tableId={tableId}
            tableName={tableStatusResult.data.tableName}
            restaurantName={tableStatusResult.data.restaurantName}
            restaurantId={tableStatusResult.data.restaurantId}
          />
        ) : tableStatusResult.data.status === TableStatusEnum.ON_GOING ? (
          <UserRegister
            tableId={tableId}
            tableName={tableStatusResult.data.tableName}
            restaurantName={tableStatusResult.data.restaurantName}
            tablePIN={pin}
          />
        ) : (
          <div className="w-full h-screen flex justify-center items-center">
            <p>Check out in progress.</p>
          </div>
        )}
      </div>
      <div className="-z-10 w-full h-[15vh] bg-gradient_black -bottom-[15vh] absolute  left-0 right-0  before:right-0 before:content-[''] before:block before:absolute before:bg-white  before:h-[100%] before:w-[100%]   before:rounded-tr-[20vh] before:rounded-b-none "></div>
    </div>
  );
};

export const Route = createFileRoute('/register/$tableId')({
  // validateSearch: (search: Record<string, unknown>): RegisterSearch => {
  //   return {
  //     pin: (search.pin as string) || '',
  //   };
  // },
  beforeLoad: () => {
    if (isAuthenticated()) {
      throw redirect({
        to: '/order',
        replace: true,
      });
    }
  },
  component: Register,
});

export default Register;
