import { redirect, Router, RouterProvider } from '@tanstack/react-router';
import { useCartStore } from './store/cartStore';
import { useEffect } from 'react';
import { useAuthStore } from './store/authStore';
import ShowToast from './components/Toast';

function App({ router }: { router: Router }) {
  const { socket, initializeSocket, joinSession } = useCartStore((state) => ({
    socket: state.socket,
    initializeSocket: state.initializeSocket,
    joinSession: state.joinSession,
  }));
  const { authToken, userName } = useAuthStore((state) => ({
    authToken: state.authToken,
    userName: state.userName,
  }));

  useEffect(() => {
    if (userName && !socket) {
      const cleanup = initializeSocket();
      try {
        joinSession(userName);
      } catch (e) {
        ShowToast({ message: 'Error in joining session. You will be redirected in 5 seconds', type: 'error' });
        setTimeout(() => {
          redirect({
            to: '/',
            replace: true,
          });
        }, 5000);
      }
      return () => {
        cleanup();
      };
    }
  }, [authToken, userName]);
  return <RouterProvider router={router} />;
}

export default App;
